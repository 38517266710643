/* You can add global styles to this file, and also import other style files */
:root {
  --toolbar-height: 48px;
  --backdrop-display: "block";
}

html,
body {
  margin: 0;
  height: 100%;

  font-family: "Open Sans", "Exo", "Roboto";

  ::-webkit-scrollbar {
    display: none;
  }
}

a[href^="https://maps.google.com/maps"] {
  display: none !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  appearance: textfield;
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

.mat-drawer-content {
  background-color: white;
}

.mat-grid-tile {
  outline: 3px solid grey;
}

mat-dialog-container {
  padding: 0 !important;

  .mat-dialog-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    padding: 8px 8px 8px 16px;
    background: rgba(0, 0, 0, 0.03);

    font-size: 1rem;

    .mat-icon-button {
      width: 28px;
      height: 28px;
      line-height: 1;
    }
  }

  .mat-dialog-content {
    max-height: 100vh;

    padding: 16px 16px 12px 16px !important;
    margin: 0 !important;

    form {
      max-height: 80vh;
      overflow: visible;

      display: flex;
      flex-direction: column;

      button[type="submit"] {
        width: 100%;
        // margin-top: 12px;
        // margin-bottom: 12px;
      }
    }
  }

  .mat-dialog-footer {
    padding: 12px 12px !important;
    margin: 0 !important;
  }
}

mat-form-field[hidden] {
  display: none;
}

mat-form-field.no-label {
  .mat-form-field-infix {
    border-top: 0.5em solid transparent;
  }
}

mat-form-field.no-padding {
  .mat-form-field-wrapper {
    padding-bottom: 0;
  }
}

mat-form-field.grow {
  width: 100%;
}

mat-form-field.slim {
  .mat-form-field-infix {
    padding: 0.4em 0 0.6em 0;
  }

  .mat-select-arrow-wrapper {
    transform: translateY(-10%);
  }

  .mat-form-field-label {
    top: 1.34375em;
  }
}

mat-form-field .mat-form-field-wrapper {
  padding-bottom: 0.75em;
}

mat-form-field.slim.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
  transform: translateY(-1.1em) scale(0.75);
  width: 133.33333%;
}

.mat-form-row {
  display: flex;
  justify-content: space-between;

  .mat-form-field {
    min-width: 0;
    width: 100%;
  }

  .mat-form-field.shorter {
    width: 50% !important;
  }

  .mat-form-field.shortest {
    width: 25% !important;
  }

  .mat-form-field:not(first-child) {
    margin-left: 6px;
  }

  .mat-form-field:not(last-child) {
    margin-right: 6px;
  }

  .mat-form-container {
    padding-bottom: 0.75em;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.mat-form-checkbox {
  padding: 0 0 0.75em 16px;
}

.mat-form-subete {
  display: flex;
}

mat-horizontal-stepper.slim {
  .mat-horizontal-stepper-header {
    height: 32px;
  }
}

mat-tab-group.slim {
  .mat-tab-label {
    height: 32px;
  }
}

button.slim {
  margin: 0.28em 0;
  height: 43px;
}

button.grow {
  width: 100%;
}

button:focus {
  outline: none !important;
}

section.card-container {
  width: 320px;
  height: 100%;
  z-index: 9;

  // box-shadow: inset 0px 0px 0px 1px red;

  .card-content {
    margin: 8px;
    height: calc(100% - 16px);

    border-radius: 8px;
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.1), 0 3px 5px 0 rgba(0, 0, 0, 0.07),
      0 0px 9px 0 rgba(0, 0, 0, 0.06);

    display: flex;
    flex-direction: column;

    .header {
      background-color: rgba(255, 255, 255, 0.15); // #090445C0;
      backdrop-filter: blur(5px);

      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }

    .header>article {
      margin-left: 8px;
      margin-right: 8px;

      padding-top: 4px;
      padding-bottom: 4px;

      color: #090445;
      font-size: 14px;
      font-family: "Exo";
      font-weight: bold;
      text-align: center;

      border-bottom: 1px solid #09044540;
    }

    .body {
      flex-grow: 1;
      padding: 8px;

      font-size: 12px;

      background-color: rgba(255, 255, 255, 0.15);
      backdrop-filter: blur(5px);

      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;

      overflow-x: hidden;
      overflow-y: hidden;

      // border-top: 1px solid #09044520;
      // border-left: 2px solid #090445A0;
      // border-right: 2px solid #090445A0;
      // border-bottom: 2px solid #090445A0;
    }
  }
}

section.widget-container {
  width: 100%;
  height: 100%;

  // .item-header {
  // 	padding-top: 2px;
  // 	font-size: 1.1rem;
  // 	font-weight: bold;
  // 	text-align: center;
  // 	// border: 1px solid red;
  // }
  // .widget-content {
  // 	display: flex;
  // 	justify-content: center;
  // 	align-items: center;
  // }

  .widget-content {
    position: relative;
    margin: 0;

    width: 100%;
    height: calc(100% - 8px);

    display: flex;
    flex-direction: column;

    // box-shadow: inset 0px 0px 0px 1px blue;

    .header {
      flex: 0 1 auto;

      color: #464646;
      font-family: Exo;
      font-size: 13px;
      font-weight: bold;
      padding: 4px 0px 2px 0px;
      text-align: center;
    }

    .body {
      flex: 1 1 auto;
      height: 100%;
      // box-shadow: inset 0px 0px 0px 1px red;
    }

    .footer {
      flex: 0 1 8px;
    }

    .widget-tabs {
      height: 100%;
      // box-shadow: inset 0px 0px 0px 1px green;

      .mat-tab-header {
        height: 10px;
        border-top: none !important;
      }

      .mat-tab-label {
        margin: 0 2px;
        padding: 0;

        min-width: 40px;
        width: 40px;
        height: 10px;

        background-color: gray;
        background-clip: padding-box;
        // border-top: 2px solid transparent;
        border-bottom: 7px solid transparent;
        opacity: 0.5;
      }

      .mat-tab-label-active {
        background-color: black;
        // opacity: 0.5;
      }

      .mat-ink-bar {
        visibility: hidden !important;
      }

      .mat-tab-body-wrapper {
        height: 100%;
        // box-shadow: inset 0px 0px 0px 1px red;
      }

      .mat-tab-body-content {
        overflow: hidden;
      }

      // .mat-tab-body-active {
      // 	height: 100%;
      // 	// box-shadow: inset 0px 0px 0px 1px yellow;
      // }
    }

    // .indicators {
    // 	margin: 0;
    // 	padding: 0;

    // 	z-index: 999;
    // 	display: flex;
    // 	justify-content: center;
    // 	list-style: none;
    // }

    // .indicators li {
    // 	box-sizing: content-box;
    // 	flex: 0 1 auto;
    // 	width: 30px;
    // 	height: 3px;
    // 	margin-right: 3px;
    // 	margin-left: 3px;
    // 	text-indent: -999px;

    // 	cursor: pointer;
    // 	background-color: #000;
    // 	background-clip: padding-box;
    // 	border-top: 2px solid transparent;
    // 	border-bottom: 2px solid transparent;
    // 	opacity: 0.5;
    // 	transition: opacity 0.6s ease;

    // 	&:hover {
    // 		background-color: #000;
    // 	}
    // }

    // .indicators .active {
    // 	opacity: 1;
    // }
  }

  .widget-option {
    display: none;

    position: absolute;
    top: 6px;
    left: 6px;

    z-index: 99;

    color: darkgray;

    .mat-icon {
      font-size: 1.25rem;
    }

    .mat-icon:hover {
      cursor: pointer;
      color: orange;
    }
  }

  &:hover .widget-option {
    display: inline-block;
  }
}

.prev,
.next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  margin-top: -20px;
  width: auto;
  padding: 16px 8px;
  color: rgba(#808080, 0.5);
  font-weight: bold;
  font-size: 18px;
  text-decoration: none;
  transition: 0.6s ease;
  user-select: none;

  z-index: 9;
}

// /* Position the "next button" to the left */
// .left {
// 	left: 0;
// 	border-radius: 0 3px 3px 0;
// }

/* Position the "next button" to the right */
.next {
  right: 0;
  border-radius: 3px 0 0 3px;
}

/* On hover, add a black background color with a little bit see-through */
.prev:hover,
.next:hover {
  color: white;
  background-color: rgba(0, 0, 0, 0.8);
}

.icon-button {
  padding-left: 4px;
  padding-right: 4px;
}

.icon-button.small {
  font-size: 16px;
}

.icon-button:hover {
  cursor: pointer;
  color: gray;
}

.hide {
  transition: transform 0.5s cubic-bezier(0.2, 1, 0.3, 1);
  // transform-origin: 50vw 50vh;
  transform: perspective(1280px) translate3d(0, 48vh, 0) rotate3d(1, 0, 0, 85deg);
  pointer-events: none;
}

form.no-padding {
  .mat-form-field-wrapper {
    padding-bottom: 0.5em;
  }
}

.mat-checkbox-double-line {
  padding-bottom: 0.5em;
  margin-left: 0.5em;
  margin-right: 0.5em;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .mat-checkbox-inner-container {
    margin-left: auto;
  }
}

.backdrop-toggle {
  background: rgba(0, 0, 0, 0.32);
  display: var(--backdrop-display);
}

.on {
  color: #499f76 !important;
}

.off {
  color: lightgray;
}

.online {
  color: #04b145 !important;
}

.offline {
  color: #f04743;
}

.error {
  color: darkred;
}

.dashboard-grid {
  .gridster-row {
    z-index: 9;
    border-top: 1px dashed darkgray;
    border-bottom: 1px dashed darkgray;
  }

  .gridster-column {
    z-index: 9;
    border-left: 1px dashed darkgray;
    border-right: 1px dashed darkgray;
  }
}

.dashboard-map {
  ::ng-deep .gm-style .gm-style-iw-c {
    padding: 6px !important;
    // border: 1px solid red;
    // background-color: darkgray !important;
  }

  ::ng-deep .gm-style-iw>button {
    display: none !important;
  }

  ::ng-deep .gm-style .gm-style-iw-d {
    // border: 1px solid red;
    overflow: hidden !important;
  }

  ::ng-deep .info-container {
    display: flex;
    justify-content: center;
    align-items: center;

    .vert-divider {
      margin-left: 4px;
      padding-right: 4px;
      height: 14px;
      border-left: 1px solid lightgray;
    }

    .mat-icon {
      width: auto;
      height: auto;

      font-size: 16px;

      &:hover {
        cursor: pointer;
      }
    }
  }
}

.horizontal-menu {
  max-width: none !important;

  .mat-menu-item {
    overflow: visible;
  }

  .mat-menu-content {
    display: flex;
    padding: 8px 4px;
  }

  .mat-menu-content>div {
    padding: 0;

    img {
      border: 1px solid lightgray;
      width: 46px;
      height: 46px;

      margin-left: 4px;
      margin-right: 4px;
    }
  }
}

.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 22px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 3px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked+.slider {
  background-color: #60b286;
}

input:focus+.slider {
  box-shadow: 0 0 1px #60b286;
}

input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

/*
 * [SOO - 2022-07-23] Removed cause it is destroying the styling for all the pages
 *
::-webkit-scrollbar {
    width: 4px;
    overflow-y: scroll;
    background: transparent;
    box-shadow: inset 0 0 4px #707070;
}

::-webkit-scrollbar-thumb {
    background: gray;
    border-radius: 10px;
}
*/

// ::ng-deep .mat-accent .mat-slider-track-fill, .mat-accent .mat-slider-thumb, .mat-accent .mat-slider-thumb-label{
//   background-color: #5469D4 !important;
// }

::ng-deep .mat-accent .mat-slider-track-fill {
  background-color: #5469D4 !important;
  height: 10px !important;
}

::ng-deep .mat-slider-thumb-label {
  background-color: #fff !important;
  border: solid 1px #C1C9D2 !important;
}

::ng-deep .mat-slider-thumb {
  background-color: #fff !important;
  border: solid 1px #C1C9D2 !important;
  box-shadow: 0px 0px 4px 0px rgba(0, 2, 5, 0.2) !important;
}

::ng-deep .mat-slider-horizontal .mat-slider-wrapper{
  height: 5px !important;
}

::ng-deep .mat-slider-horizontal .mat-slider-track-wrapper{
  height: 4px !important;
  border-radius: 10px;
}

::ng-deep .mat-slider-horizontal .mat-slider-track-background{
  height: 4px !important;
  background-color: #C1C9D2 !important;
}